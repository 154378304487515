import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import FiduLogo from "../../svg/FiduLogo"
import styled from "styled-components";
import {COLORS, device} from "../../../styles/constants";
import MenuIcon from '@material-ui/icons/Menu';
import {Divider, Drawer} from "@material-ui/core";

const HeaderRoot = styled.header`
      background: ${props => props.scrolledToTop ? COLORS.transparent : COLORS.blue};
      margin-bottom: 1.45rem;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 200;
      width: 100vw;
      transition: 0.5s;
`

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding: 1rem;
    justify-content: space-between;
    padding-right: 30px;
`

const Show = styled.div`

    display: none;
    
    @media ${props => props.device} {
        display: inherit;
    }

`

const Hide = styled.div`

    display: inherit;
    
    @media ${props => props.device} {
        display: none;
    }

`

const LogoContainer = styled.div`

    display: flex;
    align-items: center;

    .menu-icon {
        margin-right: 20px;
        width: 35px;
        height: 100%;
        color: ${props => props.scrolledToTop ? COLORS.blue : COLORS.lightWhite};
        transition: 2s;
    }
`

const NavigationContainer = styled.nav`
    
    .navigation-item {
        color: ${props => props.scrolledToTop ? COLORS.blue : COLORS.lightWhite}};
        align-self: center;
        text-decoration: none;
    }
    
`

const DrawerContainer = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 200px;
    
`

const DrawerHeader = styled.div`
    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 86px;
    width: 100%;
    background: ${props => props.scrolledToTop ? COLORS.white : COLORS.blue};
    margin-bottom: 50px;
    
`

const DrawerContent = styled.div`
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
`

const Header = ({ siteTitle }) => {

    const [scrolledToTop, setScrolledToTop] = useState(true);
    const [logoColor, setLogoColor] = useState(COLORS.blue);
    const [isDrawerOpen, setDrawerOpen] = useState(false);

    const handleScroll = () => {
        if (window.pageYOffset > 0) {
            setScrolledToTop(false)
            setLogoColor(COLORS.lightWhite);
        } else {
            setScrolledToTop(true)
            setLogoColor(COLORS.blue);
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    },[scrolledToTop])

    const openDrawer = () => {
        setDrawerOpen(true);
    }

    const closeDrawer = () => {
        setDrawerOpen(false);
    }

    const Navigation = () => {
        return(
            <>
                <Link className="navigation-item" to="/" onClick={closeDrawer}>Moms</Link>
                <span className="navigation-item">&nbsp;&nbsp;</span>
                <Link className="navigation-item" to="/midwives" onClick={closeDrawer}>Midwives</Link>
                <span className="navigation-item">&nbsp;&nbsp;</span>
            </>
        );
    }

    return (
        <>
            <HeaderRoot scrolledToTop={scrolledToTop}>
                <HeaderContainer>
                    <LogoContainer scrolledToTop={scrolledToTop}>
                        <Hide device={device.tablet}>
                            <MenuIcon className="menu-icon" onClick={openDrawer}/>
                        </Hide>
                        <FiduLogo height={50} color={logoColor} />
                    </LogoContainer>
                    <Show device={device.tablet}>
                        <NavigationContainer scrolledToTop={scrolledToTop}>
                            <Navigation />
                        </NavigationContainer>
                    </Show>
                </HeaderContainer>
            </HeaderRoot>
            <Hide device={device.tablet}>
                <Drawer anchor="left" open={isDrawerOpen} onClose={closeDrawer}>
                    <DrawerContainer>
                        <DrawerHeader scrolledToTop={scrolledToTop}>
                            <FiduLogo height={50} color={logoColor} />
                        </DrawerHeader>
                        <DrawerContent>
                            <Navigation />
                        </DrawerContent>
                    </DrawerContainer>
                </Drawer>
            </Hide>
        </>
    );
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
