import React from 'react'
import StyledSvg from "./StyledSvg";
import styled from "styled-components";

const Svg = styled(StyledSvg)`
   
    .cls-1 {
        transition: fill 2s;
    }
   
`

export default function FiduLogo(props) {
    const { width, height, color } = props;
    return (
        <Svg width={width} height={height} viewBox="0 0 1000 353.84">
                <path fill={color} className="cls-1"
                      d="M320.88,480.72V667.65c0,21.59,12.43,34,34.11,34h25.45V592.23h62.18a28.86,28.86,0,0,0,28.84-29.47V543.4h-92.9V487.72H453.9c13.1,0,21-5.48,25.23-10.08a30.45,30.45,0,0,0,8.13-20.52V437H367.77C338.07,437,320.88,444.16,320.88,480.72Z"
                      transform="translate(-40 -363.08)" />
                <path fill={color} className="cls-1" d="M540.11,480.34c16.06,0,29.62-13.52,29.62-29.51a29.46,29.46,0,1,0-29.62,29.51Z"
                      transform="translate(-40 -363.08)" />
                <path fill={color} className="cls-1"
                      d="M570.37,631.69v-94.4c0-26.74-14.24-41.47-40.12-41.47H511.94V636.56c0,43.16,22.93,66.93,64.57,66.93,23.59,0,34.11-10.59,34.11-34.34V654.28H597.2C576.64,654.28,570.37,649,570.37,631.69Z"
                      transform="translate(-40 -363.08)" />
                <path fill={color} className="cls-1"
                      d="M779.82,423.9H760.38v85.21C749.12,496,730.94,486.84,714,486.84c-59.75,0-95.41,42.38-95.41,113.38,0,28.06,10,54.49,28.21,74.43,18.52,20.29,43.46,31.46,70.21,31.46,66.35,0,101.43-35.19,101.43-101.77V462C818.44,437.78,804.36,423.9,779.82,423.9ZM759.63,602.09c0,36.13-12,53.69-36.61,53.69-27.53,0-46.75-26.85-46.75-65.3,0-31.39,15.67-53.31,38.1-53.31C744,537.17,759.63,559.62,759.63,602.09Z"
                      transform="translate(-40 -363.08)" />
                <path fill={color} className="cls-1"
                      d="M994.23,491.33h-13V608.84c0,20.39-5.44,44.69-31.34,44.69-23.84,0-37-17.07-37-48.07V531.29c0-26.52-15.39-40-45.76-40H853.69v119c0,57.29,37.59,95.78,93.53,95.78,30.51,0,53.88-8.49,69.47-25.25S1040,639.37,1040,607V531.29C1040,504.77,1024.6,491.33,994.23,491.33Z"
                      transform="translate(-40 -363.08)" />
            <rect fill="none" className="cls-2" x="249.51" y="284.6" width="0.21" />
            <path fill="#f7b0c2" className="cls-3"
                  d="M289.51,578.45H220a71.16,71.16,0,0,0-10.59.79,69,69,0,0,0-6.78,1.39q-1.67.42-3.3.93a66.64,66.64,0,0,0-6.39,2.33c-1,.44-2.06.9-3.08,1.38-2,1-4,2-5.91,3.2a70.06,70.06,0,0,0-17.59,15.17q-2.13,2.57-4,5.33a68.69,68.69,0,0,0-11.87,38.71v69.24H220a69.45,69.45,0,0,0,36.05-10c1.91-1.16,3.76-2.41,5.54-3.73s3.5-2.75,5.15-4.24c.82-.74,1.63-1.51,2.42-2.29q2.36-2.35,4.48-4.92c.72-.87,2.29-2.81,4-5.33A69.87,69.87,0,0,0,284,674.63a71.43,71.43,0,0,0,5.68-27Z"
                  transform="translate(-40 -363.08)" />
            <path fill="#7868bf" className="cls-4"
                  d="M288.37,486.77c0-8.24-.83-52.06-36.37-87.46s-79.55-36.23-87.82-36.23H40V486.77C40,543,77.7,590.49,129.31,605.5A100.25,100.25,0,0,1,220,548h68.38v-61.2Z"
                  transform="translate(-40 -363.08)" />
        </Svg>
    );
}