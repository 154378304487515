export const COLORS = {
  white: "#FFFFFF",
  lightWhite: "#FBFDFF",
  lightGray: "#EEEDEC",
  mediumGray: "#B0B8BA",
  gray: "#A0A4A6",
  blue: "#46467a",
  pink: "#f7b0c2",
  purple: "#7868bf",
  transparent : "transparent"
}

export const BORDER_RADIUS = "10px"
export const HEADER_HEIGHT = 82
export const FOOTER_HEIGHT = 98

const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px'
}

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`
};
