import React from "react"

import {COLORS, device, FOOTER_HEIGHT} from "../../../styles/constants"

import styled from "styled-components";

const HeaderRoot = styled.footer`
      background: ${COLORS.blue};
      width: 100%;
      height: ${FOOTER_HEIGHT}px;
`

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    width: 100%;
    height: 100%;
    
    @media ${device.tablet} { 
         flex-direction: row;
    }
`

const ContactUsLink = styled.a`
    text-decoration: none;
    color: ${COLORS.lightWhite};
    font-weight: 700;
    
    &:active{
        color: ${COLORS.lightWhite};
    }
    
    &:hover{
        color: ${COLORS.lightWhite};
    }
    
    &:visited{
        color: ${COLORS.lightWhite};
    }
`

const Footer = ({ siteTitle }) => {

    return (
        <HeaderRoot>
            <HeaderContainer>
                <ContactUsLink href="mailto:office@fidu.health">
                        Contact Us
                </ContactUsLink>
                <div style={{ color: COLORS.lightWhite}}>
                    Hohlweggasse 2/27, 1030 Vienna, Austria
                </div>
                <div style={{ color: COLORS.lightWhite}}>
                    © {new Date().getFullYear()}
                    {` `}
                    {siteTitle}
                </div>
            </HeaderContainer>
        </HeaderRoot>
    )
}

export default Footer
